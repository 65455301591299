<template>
  <div class="pages">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-mobile">
            <div class="column">
              <h2 class="is-3 title">
                {{ $t('Topline Checks') }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section v-if="isMounted" class="section">
      <div class="container">
        <div class="columns is-mobi is-mini-gap is-multiline">
          <div class="column mt-2">
            <SearchFilter
              ref="searchFilter"
              @filter="fetchData"
            />
          </div>
          <div class="column is-narrow mt-2">
            <a-tooltip placement="top">
              <template #title>
                <span
                  v-if="!checkedBtnStatus"
                >{{ $t('Select and fix the topline first.') }}</span>
              </template>
              <a-button
                class="ant-btn ant-btn-lg ant-btn-primary"
                :loading="loadingCheckBtn"
                :disabled="!checkedBtnStatus"
                @click="handleCheck"
              >
                {{ $t('Checked') }}
              </a-button>
            </a-tooltip>
          </div>
        </div>

        <a-table
          row-key="toplineId"
          :columns="columns"
          :data-source="data"
          :loading="isFetching"
          :row-selection="rowSelection"
          :scroll="{ x: 1500 }"
          :pagination="pagination"
          @change="handleTableChange"
        >
          <template #error="{ record }">
            <span v-if="record.error" class="has-text-danger">{{ $t(record.error) }}</span>
            <span v-else class="has-text-success">{{ $t('Correct') }}</span>
          </template>
          <template #id="{ record }">
            <p class="has-text-weight-bold">
              <span
                class="is-clickable hover-underline has-text-primary"
                @click="goToReservation(record.id.srs)"
              >
                {{ $t('SRS') }}
              </span>
            </p>
            <p class="has-text-weight-bold">
              <span
                class="is-clickable hover-underline has-text-primary"
                @click="openToplineModal(record.id.topline)"
              >
                {{ $t('Topline') }}
              </span>
            </p>
          </template>
          <template
            v-for="(col, idx) in columns.slice(2)"
            :key="idx"
            #[col.key]="{ record }"
          >
            <ColumnData :key-index="col.key" :data="record[col.key]" />
          </template>
        </a-table>
      </div>
      <Modal ref="editModal" width="85vw">
        <template #default="{ hide }">
          <AddTopLineForm
            :value="selectedTopline"
            @close="() => {
              hide();
              fetchData();
              fetchTravelWebsite();
            }"
            @cancel="() => {
              hide();
              selectedTopline = undefined;
            }"
          />
        </template>
      </Modal>
    </section>
  </div>
</template>

<i18n>
{
  "en": {
    "Checked": "Checked",
    "Status (Data Classification) does not match": "Status (Data Classification) does not match",
    "Topline": "Topline",
    "Check in Date": "Check in Date",
    "Partner": "Partner",
    "Sales without OTA Fees": "Sales without OTA Fees",
    "Topline Checks": "Topline Checks",
    "Pax": "Pax",
    "Error Message": "Error Message",
  },
  "ja": {
    "Checked": "確認済",
    "Status (Data Classification) does not match": "ステータス（データ区分）が一致しません",
    "Topline": "トップライン",
    "Check in Date": "チェックイン日",
    "Check out Date": "チェックアウト日",
    "Partner": "取引先",
    "Sales without OTA Fees": "OTA手数料抜き売上",
    "Topline Checks": "トップライン確認",
    "Pax": "人数",
    "Error Message": "エラーメッセージ",
  }
}
</i18n>

<script>
/* eslint-disable */
import Modal from '@/components/ModalWide';
import Pagination from '@/components/mixins/pagination';
import AddTopLineForm from '../topline/components/AddTopLineForm';
import SearchFilter from './components/SearchFilter';
import ColumnData from './components/ColumnData';
import ColumnLists from './components/ColumnLists';

export default {
  name: 'ToplineCheckLists',
  components: {
    SearchFilter,
    ColumnData,
    Modal,
    AddTopLineForm,
  },
  mixins: [Pagination, ColumnLists],
  data() {
    return {
      loadingCheckBtn: false,
      isMounted: true,
      data: undefined,
      isFetching: true,
      selectedTopline: undefined,
      selectedRows: [],
    };
  },
  computed: {
    rowSelection() {
      const selectedRowKeys = this.selectedRows ? this.selectedRows.map((row) => row.toplineId) : [];

      return {
        selectedRowKeys,
        onChange: (_key, row) => {
          this.selectedRows = row;
        },
      };
    },
    checkedBtnStatus() {
      if (this.selectedRows.length) {
        return this.selectedRows.every((e) => e.error === null);
      }

      return false;
    },
  },
  created() {
    this.fetchData();
  },
  beforeRouteLeave() {
    /**
     * when :row-selection="rowSelection" is present,
     * vue router is unable to change the Page
     * although the route URI is changed already
     *
     * Did this simple hack to hide table component
     * before route leave
     */
    this.isMounted = false;
  },
  methods: {
    async handleTableChange(pagination) {
      await this.preparePagination(pagination);
      await this.fetchData();
    },
    fetchData() {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');
      this.isFetching = true;
      this.selectedRows = [];

      this.$store.dispatch('rms-topline-checks/list', this.$route.query)
        .then((resp) => {
          const { data, pagination } = resp;

          this.data = data;

          this.setPagination(pagination);
        })
        .finally(() => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
          this.isFetching = false;
        });
    },
    goToReservation(id) {
      if (id) {
        window.open(`/reservation/${id}/basic-info`);
      }
    },
    openToplineModal(id) {
      this.$store.dispatch('rms-toplines/shows', id)
        .then((res) => {
          this.selectedTopline = res.data;
          this.$refs.editModal.show();
        });
    },
    fetchTravelWebsite() {
      this.$refs.searchFilter.triggerFetchTravelWebiste();
    },
    handleCheck() {
      const ids = this.selectedRows ? this.selectedRows.map((e) => e.toplineId) : [];
      this.loadingCheckBtn = true;

      this.$store.dispatch('rms-topline-checks/check', { ids })
        .then(() => {
          this.fetchData();
        })
        .finally(() => {
          this.loadingCheckBtn = false;
        });
    },
  },
};
</script>

<style scoped>
.hover-underline:hover {
  text-decoration: underline;
}
</style>
