export default {
  data() {
    return {
      columns: [
        {
          title: this.$t('Error message'),
          width: 200,
          dataIndex: 'error',
          key: 'error',
          fixed: 'left',
          slots: { customRender: 'error' },
        },
        {
          title: this.$t('ID'),
          width: 120,
          dataIndex: 'id',
          key: 'id',
          fixed: 'left',
          slots: { customRender: 'id' },
        },
        {
          width: 150,
          dataIndex: 'externalWebsiteReservationId',
          key: 'externalWebsiteReservationId',
          fixed: 'left',
          slots: { customRender: 'externalWebsiteReservationId' },
        },
        {
          title: this.$t('Reservation Number'),
          width: 150,
          dataIndex: 'reservationNumber',
          key: 'reservationNumber',
          slots: { customRender: 'reservationNumber' },
        },
        {
          title: this.$t('Status'),
          width: 150,
          dataIndex: 'status',
          key: 'status',
          slots: { customRender: 'status' },
        },
        {
          title: this.$t('Total charge'),
          width: 200,
          dataIndex: 'totalSalesAmount',
          key: 'totalSalesAmount',
          slots: { customRender: 'totalSalesAmount' },
        },
        {
          title: this.$t('Check in Date'),
          width: 150,
          dataIndex: 'checkin',
          key: 'checkin',
          slots: { customRender: 'checkin' },
        },
        {
          title: this.$t('Check out Date'),
          width: 150,
          dataIndex: 'checkout',
          key: 'checkout',
          slots: { customRender: 'checkout' },
        },
        {
          title: this.$t('Pax'),
          width: 150,
          dataIndex: 'numberOfGuest',
          key: 'numberOfGuest',
          slots: { customRender: 'numberOfGuest' },
        },
        {
          title: this.$t('Property'),
          width: 150,
          dataIndex: 'propertyName',
          key: 'propertyName',
          slots: { customRender: 'propertyName' },
        },
        {
          title: this.$t('Partner'),
          width: 250,
          dataIndex: 'externalWebsiteOtaCode',
          key: 'externalWebsiteOtaCode',
          slots: { customRender: 'externalWebsiteOtaCode' },
        },
        {
          title: this.$t('Sales without OTA Fees'),
          width: 250,
          dataIndex: 'salesWithoutOtaFees',
          key: 'salesWithoutOtaFees',
          slots: { customRender: 'salesWithoutOtaFees' },
        },
      ],
    };
  },
};
