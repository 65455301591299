<template>
  <div class="columns is-multiline">
    <div class="column is-narrow">
      <InputTravelWebsite
        ref="inputTravelWebsite"
        v-model:value="travelWebsite"
        style="width: 200px;"
        @change="onFilterChange"
      />
    </div>
    <div class="column is-narrow">
      <InputProperty
        v-model:value="propertyId"
        style="width: 200px;"
        @change="onFilterChange"
      />
    </div>
    <div class="column is-narrow">
      <a-input-group compact>
        <a-select
          v-model:value="dateSelection"
          class="is-mobile-field"
          :placeholder="$t('Choose date')"
          style="min-width: 120px"
          :size="size"
        >
          <a-select-option v-for="o in dateOptions" :key="$t(o.name)" :value="o.id">
            {{ $t(o.name) }}
          </a-select-option>
        </a-select>
        <a-range-picker
          v-model:value="range"
          class="is-mobile-field"
          style="width: 220px"
          :size="size"
          :format="dateFormat"
          @change="onFilterChange"
        >
          <a-input
            :value="rangeString"
            :placeholder="$t(`${dateSelectionText} range`)"
            :size="size"
            readonly
          >
            <template #prefix>
              <CalendarOutlined />
            </template>
          </a-input>
        </a-range-picker>
      </a-input-group>
    </div>
    <div class="column is-narrow">
      <a-select
        v-model:value="status"
        :placeholder="$t('Status')"
        style="width: 140px"
        :default-active-first-option="false"
        :size="size"
        @change="onFilterChange"
      >
        <a-select-option
          v-for="(st, id) in statuses"
          :key="id"
          :value="st.value"
          :title="$t(st.label)"
        >
          {{ $t(st.label) }}
        </a-select-option>
      </a-select>
    </div>
    <div class="column">
      <a-button
        v-show="propertyId || travelWebsite || status || range.length || dateSelection"
        :size="size"
        style="min-width: 110px"
        type="default"
        @click="handleClear"
      >
        {{ $t('Clear') }}
      </a-button>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "Choose date": "Choose date",
    "Check in date": "Check in date",
    "Check out date": "Check out date",
    "Reservation date": "Reservation date",
  },
  "ja": {
    "Choose date": "日付タイプ",
    "Check in date": "チェックイン日",
    "Check out date": "チェックアウト日",
    "Reservation date": "予約日",
  }
}
</i18n>

<script>
import debounce from 'lodash/debounce';
import { CalendarOutlined } from '@ant-design/icons-vue';
import InputTravelWebsite from '@/components/InputTravelWebsite';
import InputProperty from '@/components/InputProperty';
import { TOPLINE_DATE } from '@/config/revenue-managements';
import { STATUS } from '@/config/reservations';
import { cleanObject } from '@/utils/util';

export default {
  name: 'SearchFilter',
  components: {
    CalendarOutlined,
    InputTravelWebsite,
    InputProperty,
  },
  emits: ['filter'],
  data() {
    return {
      size: 'large',
      dateFormat: 'MM/DD/YYYY',
      travelWebsite: undefined,
      propertyId: undefined,
      status: undefined,
      range: [],
      dateSelection: undefined,
      statuses: STATUS,
      dateOptions: TOPLINE_DATE,
    };
  },
  computed: {
    rangeString() {
      return this.range.length
        ? `${this.$filters.date(this.range[0], { format: this.dateFormat })} ~ ${this.$filters.date(
          this.range[1],
          { format: this.dateFormat },
        )}`
        : '';
    },
    formattedRange() {
      const dateFormat = 'YYYY-MM-DD';
      const range = { dateFrom: undefined, dateTo: undefined };

      if (this.range.length) {
        range.dateFrom = this.$filters.date(this.range[0], { format: dateFormat });
        range.dateTo = this.$filters.date(this.range[1], { format: dateFormat });
      }

      return range;
    },
    dateSelectionText() {
      if (this.dateSelection) {
        return this.dateOptions.find((object) => object.id === this.dateSelection).name;
      }
      return 'Date';
    },
  },
  mounted() {
    this.propertyId = this.$route.query.propertyCode || undefined;
    this.travelWebsite = this.$route.query.travelWebsite || undefined;
    this.dateSelection = this.$route.query.dateField || undefined;
    this.status = this.$route.query.status || undefined;
    this.range = this.$route.query.dateFrom && this.$route.query.dateTo
      ? [this.$route.query.dateFrom, this.$route.query.dateTo] : [];
  },
  methods: {
    onFilterChange: debounce(function onFilterChange() {
      this.handleChange();
    }, 600),
    async handleChange() {
      const query = cleanObject({
        ...this.$route.query,
        ...this.formattedRange,
        page: 1, // force page 1
        dateField: this.dateSelection,
        travelWebsite: this.travelWebsite,
        propertyId: this.propertyId,
        status: this.status,
      });
      await this.$router.push({ query });
      this.$emit('filter');
    },
    triggerFetchTravelWebiste() {
      this.$refs.inputTravelWebsite.handleSearch();
    },
    handleClear() {
      this.travelWebsite = undefined;
      this.propertyId = undefined;
      this.status = undefined;
      this.range = [];
      this.dateSelection = undefined;

      this.handleChange();
    },
  },
};
</script>
