<template>
  <p>{{ rowData.srs || '-' }}</p>
  <p>{{ rowData.topline || '-' }}</p>
</template>

<script>
export default {
  name: 'ColumnData',
  props: {
    data: {
      type: Object,
      default: () => ({ srs: '-', topline: '-' }),
    },
    keyIndex: {
      type: String,
      default: '',
    },
  },
  computed: {
    rowData() {
      if (this.keyIndex === 'totalSalesAmount') {
        return {
          srs: this.$filters.currency(this.data.srs, { currency: 'JPY' }),
          topline: this.$filters.currency(this.data.topline, { currency: 'JPY' }),
        };
      }

      return {
        srs: this.data.srs,
        topline: this.data.topline,
      };
    },
  },
};
</script>
